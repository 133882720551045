import React, {useState, useEffect, Fragment } from "react"
import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import SEO from "../components/seo";

import Layout from '../components/Layout.js';
import Slider from '../components/Slider.js';
import arrowDown from '../images/icons/arrowDown.png';
import SplitSection from '../components/SplitSection.js';
import InfoSection from '../components/InfoSection.js';
import InfoAndBackground from "../components/InfoAndBackground";
import AgeGate from "../components/AgeGate";
import Cookies from "js-cookie";
import Div100vh from "react-div-100vh";
import styles from "../scss/pages/_budsummer.module.scss"
import {Container, Row, Col, Button} from 'react-bootstrap';
import craft from "../images/BudSummer/craft.png";
import rappi from "../images/BudSummer/rappi.png";
import {IsMobileDevice} from '../utils/index'; 
import left from '../images/BudSummer/leftSide.svg';
import bloque2M from '../images/BudSummer/bloque2Mobile.jpg'
import deskBloque2 from '../images/BudSummer/deskBloque2.png'

const BudSummer = ({data,props}) => {

    const [listSlider, setListSlider] = useState([]);
    const [age, setAge] = useState(true);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // document.getElementById("InfoSection").style.background = "#F90002"
        setIsMobile(IsMobileDevice())
        console.log("ok!")
        let cookie = Cookies.get('age')
        let local = sessionStorage.getItem('age')
        console.log("Cookie",cookie)
        if (cookie || local) setAge(false)
        setListSlider(data.Slider.edges.sort((a, b) => a.node.position - b.node.position));
    },[])

    return (
        !age ? (
            <Layout style={{backgroundColor:"white !important"}} summer={true}>
                <style>{'body { background: white'}</style>
            <SEO title="BudSummer"/>
                {/* <Slider sliders={listSlider}/> */}
                {/* <Div100vh>
                    <p>BudSummer</p>
                </Div100vh> */}
                <Fragment>
        {/* image background */}
        {/* {!isMobile 
            ?   <img src={slider.imageDesktop} className={styles.imgBackground}/>
            :   <img src={slider.imageMobile} className={styles.imgBackground}/>
        } */}
        {/* gradient */}
        <div className={styles.gradient}>
            {/* info */}
            <Container fluid className={styles.SlideContainer}>
                <Row className={styles.SlideRowText}>
                    <Col md={'7'} xs={'10'} className={styles.SlideColText}>
                        {/* title */}
                        {"Armá una playlist y transformá tu verano" &&
                            <Row>
                                <Col>
                                    {isMobile ? <h1 className={styles.infoTitle}>ARMÁ UNA <span>PLAYLIST </span> Y TRANSFORMÁ TU VERANO.</h1> : <h1 className={styles.infoTitle}>ARMÁ UNA <span>PLAYLIST </span>Y TRANSFORMÁ TU VERANO.</h1>}
                                </Col>
                            </Row>
                        }
                        {/* paragraph */}
                        {
                            <Row>
                                <Col>
                                    <p className={styles.infoParagraph}>Hacé tu mejor selección de temas en Spotify. <br/>Cada viernes nuestros jurados eligen un ganador para cambiarle el verano al mejor estilo de la king of beers.</p>
                                    <h4 className={styles.infoParagraph2}>*Participá por un kit con mochila, parlante, inflable, toalla pareo, remera y Buds para compartir.</h4>
                                </Col>
                            </Row>
                        }
                        {/* button */}
                        {/* {slider.textButton &&
                            <Row>
                                <Col>
                                    <Button className={styles.infoButton} href={slider.linkButton}>{slider.textButton}</Button>
                                </Col>
                            </Row>
                        } */}
                    </Col>
                </Row>
            </Container>
        </div>
    </Fragment>
            {/* arrowDown */}
            {/* <Row className="d-flex justify-content-center m-0">
                <Col xs={'auto'}>
                    <a onClick={e => document.querySelector('#InfoSection').scrollIntoView({behavior: 'smooth'})} >
                        <img src={arrowDown} className="ArrowDown"/>
                    </a>
                </Col>
            </Row> */}
            {/* info birra */}
            {isMobile ? (
                <div>
                 <SplitSection
                 budsummer={true}
                 left={
                    <img src={bloque2M} style={{width:"100vw"}}/>
                //  <InfoSection
                //      title="¿CÓMO PARTICIPAR?"
                //      paragraph="1- Creá una playlist en spotify que se llame BUD SUMMER."
                //      paragraph2="2- Compartila en stories  y mencioná a @budargentina"
                //      paragraph3="3- Si sos uno de los ganadores, nuestros jurados te explotan el summer con un montón de premios."
                //      budsummer={true}
                //      mobile={true}
                //      // button="Conocé más"
                //     />
                }
                 />
                    <div style={{width: "100%",height: "70vh"  , padding: "5%"}}>
                         <iframe src="https://open.spotify.com/embed/playlist/5xv8ewjUo2PyNvAqvNkGzI" width="100%" height="100%" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                     </div>
                 </div>
             
            ):(
                <div 
                // style={{backgroundColor:"#F90002 !important"}}
                >
                <SplitSection
                budsummer={true}
                left={
                // <InfoSection
                //     title="¿CÓMO PARTICIPAR?"
                //     paragraph="1- Creá una playlist en spotify que se llame BUD SUMMER."
                //     paragraph2="2- Compartila en stories  y mencioná a @budargentina"
                //     paragraph3="3- Si sos uno de los ganadores, nuestros jurados te explotan el summer con un montón de premios."
                //     budsummer={true}
                //     // button="Conocé más"
                    
                // />
                <img src={deskBloque2} style={{height:"82vh", paddingLeft:"7%"}}/>
            } 
                right={
                    <div className={styles.right2}>
                        <iframe src="https://open.spotify.com/embed/playlist/5xv8ewjUo2PyNvAqvNkGzI" width="100%" height="100%" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                    </div>
                    }
                />
                </div>
            )}
           
            {/* backgroundFooter */}
            <div className={styles.bloqueTres}>
                {/* <InfoAndBackground info={data.imageFooter.edges[0].node} image={data.allFile.edges[1].node.childImageSharp.fluid}/> */}
                <h2>SUMMER KIT</h2>
                <h3>Conseguí tu kit de inflable + toalla + Buds en </h3>
                <h3><a href="https://www.craftsociety.com.ar/collections/bud-summer/?utm_source=budweiser&utm_medium=store&utm_campaign=budsummer&utm_content=ad" ><img className={styles.craft} src={craft}/></a>
                <a href="https://rappi.app.link/SNTemBJEBdb" ><img className={styles.rappi} src={rappi}/></a></h3>
                {/* <h3>Solo en <img className={styles.craft} src={craft}/>
                <img className={styles.rappi} src={rappi}/></h3> */}
                
            </div>
        </Layout>
        ):(
            <AgeGate path="/budsummer"/>
        )
       
    )

}

export default BudSummer

export const data = graphql`
    query imgHomeAndImgHome {
        allFile(filter: {relativeDirectory: {eq: "Home"}}) {
            edges {
                node {
                    base
                        childImageSharp {
                            fluid (quality: 100, maxWidth: 1920) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                }
            }
        }
        Slider: allHomeSlider {
            edges {
                node {
                    title
                    paragraph
                    textButton
                    linkButton
                    position
                    imageDesktop
                    imageMobile
                }
            }
        }
        imageFooter: allHomeImage {
            edges {
                node {
                    title
                    paragraph
                    textButton
                    linkButton
                    imageDesktop
                    imageMobile
                }
            }
        }
    }`
;
